import React, { useState } from 'react';

function DuplicateRemover() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    const lines = text.split('\n').filter((line) => line.trim() !== '');
    const result = lines.join('\n');
    setOutputText(result);
  };

  const handleCopyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = outputText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  return (
    <div className="duplicate-remover">
      <div className="input-container">
        <textarea
          rows="10"
          cols="40"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Enter text here"
        />
      </div>
      <div className="output-container">
        <button onClick={handleCopyToClipboard} className="copy-button">
          Copy
        </button>
        <textarea
          rows="10"
          cols="40"
          value={outputText}
          readOnly
          placeholder="Result will appear here"
        />
      </div>
    </div>
  );
}

export default DuplicateRemover;

