import React from 'react';
import './App.css';
import DuplicateRemover from './DuplicateRemover';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Remove Empty Lines</h1>
        <p>Remove empty lines from your text.</p>
        <p id='lemon'>Ezpz 🍋 sqz</p>
        <meta name="description" content="An online tool to remove empty lines from your text. Clean up your text quickly and easily with EmptyLineRemover.com." />
        <DuplicateRemover />
        <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8559503433234162"
          crossorigin="anonymous"
        />
      </Helmet>

      </header>

    </div>
  );
}

export default App;

